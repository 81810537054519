<template>
    <div id="containerProfessionnalisation2" class="containerAffichage2">
        <bloc-professionnalisation  :sitePro=sitePro />
    </div>
</template>


<script>
import Professionnalisation from '@/components/pro/Professionnalisation.vue'


export default {
    name: 'Professionnalisation',
    data() {
        return {
            onlyOnce:true,
            _g_iFrameName:'blockrandom',
            _g_targetToObserve:null,
            _g_verticalMargin:20, // parfois, la hauteur de certains éléments est mal calculée, à qques pix près.
        }
    },

    components: {
        'bloc-professionnalisation': Professionnalisation,
    },
    props: {
        sitePro: {
            type: Boolean
        }
    },
    created() {
        if (this.sitePro) {
            this.onlyOnce = true;
            this.$parent.sitePRO = true;
            this.setUpAutoHeight('containerProfessionnalisation2');
        }

        // document.domain = 'orientest.pro'; ne marche PLUS sous Chrome => window.parent.postMessage
    },
/*
    watch:{
        eltLink(newValue) {
                if (newValue) {
                    this.setUpscroll();
                }
            }
        },
*/
    computed: {
/*
        eltLink() {
            return(document.getElementById("ancre_"+this.parentFrameLink));
        },

        isDansIframe() {
            if (window.location !== window.parent.location) {
                return(1);
            }
            return(0);
        },
        parentFrame() {
            if (this.isDansIframe) {
                return(document.referrer);
            }
            return null;
        },
        parentFrameLink() {
            if (this.parentFrame) {
                let leLink = this.parentFrame.split("?link=");
                if (leLink.length > 1)
                    return(leLink[1]);
            }
            return null;
        },
        positionAncre() {
            if (this.parentFrameLink) {
                let ref2Scroll = "ancre_"+this.parentFrameLink;
                if (this.$refs[ref2Scroll] && this.$refs[ref2Scroll][0]) {
                    const top = this.$refs.[ref2Scroll][0].getBoundingClientRect().top;
                    if (top && this.onlyOnce) {
                        this.onlyOnce = false;
                        return(top);
                    }
                }
                return(0);
            }
            return(0);
        }
*/
    },
    methods: {
        setUpAutoHeight(observableNodeId) {
            console.log('Iframe Loaded, call installObserver');
            /*
            let cmd = "this.installObserver('" + observableNodeId + "');"
            setTimeout(cmd, 100);
            */
            setTimeout(function () { this.installObserver(observableNodeId) }.bind(this), 500)
        },
//            let id2Scroll = "ancre_"+this.parentFrameLink;
        installObserver(observableNodeId) {
            console.log('installObserver observableNodeId : ', observableNodeId);
            // définition de la cible à observer
            //let _g_iframe = document.getElementById(observableNodeId);
            //let _g_iframe = document.getElementById(this._g_iFrameName);
            //if (!_g_iframe) return;
            //this._g_targetToObserve = document.getElementById(observableNodeId)
            //this._g_targetToObserve = _g_iframe.contentWindow.document.getElementById(observableNodeId);
            this._g_targetToObserve = document.getElementById(observableNodeId);
            if (this._g_targetToObserve) { // il faut le faire au moins une fois en cas de F5 car rien ne change alors
                let newHeight = this._g_targetToObserve.scrollHeight;
                console.log('installObserver newHeight : ', newHeight);
                window.parent.postMessage({type: 'resizeIframe', height: newHeight}, '*');
            }
            // définition de la callback de l'observer
            let observer = new MutationObserver(this.doTriggerObserver);
            // quels événements observer sur la cible
            observer.observe(this._g_targetToObserve, {
                childList: true,
                subtree: true,
                attributes: true,
            });
        },
        doTriggerObserver(mutationList, observer) { // Déclenchement de la callback lors de la modification du noeud observé (et de ses enfants)
            if (this._g_targetToObserve) {
                let newHeight = this._g_targetToObserve.scrollHeight;
                console.log('doTriggerObserver hauteur nouvelle : ', newHeight);
                setTimeout(function () { this.resizeIframe(newHeight) }.bind(this), 10)
            }
        },
        resizeIframe(newHeight) { // Notification du parent par un 'postMessage'
            window.parent.postMessage({type: 'resizeIframe', height: newHeight}, '*');
        }
/*      ,
        setUpscroll() {
            console.log('Iframe Loaded, call installObserver setUpscroll');
            setTimeout(function () { this.installObserver2() }.bind(this), 1000)
        },
        installObserver2() {
            this._g_targetToObserve2 = document.getElementById("ancre_"+this.parentFrameLink);
            // définition de la callback de l'observer
            let observer = new MutationObserver(this.doTriggerObserver2);
            // quels événements observer sur la cible
            observer.observe(this._g_targetToObserve2, {
                childList: true,
                subtree: true,
                attributes: true,
            });
        },
        doTriggerObserver2(mutationList, observer) { // Déclenchement de la callback lors de la modification du noeud observé (et de ses enfants)
            if (this._g_targetToObserve2) {
                let newPositionAncre = this.positionAncre;
                setTimeout(function () { this.getPositionAncre(newPositionAncre) }.bind(this), 10)
            }
        },
        getPositionAncre(newPositionAncre) { // Notification du parent par un 'postMessage'
            window.parent.postMessage({type: 'topAncre', ancre: newPositionAncre}, '*');
        }
*/
    }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="scss">

#app {
    #containerProfessionnalisation2 {
        border: solid 1px #ccc;
        #professionnalisation {
            padding:1em 0;
            #popupFormation {
                top:0;
                transform:none;
            }
        }
        #fondPopup {
            opacity: .3;
        }
    }
    .scrollup {
        display: none !important;
    }
}

</style>

